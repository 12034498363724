import banner from "../assets/banner.webp";
import { useText } from "../utils/LanguageContext";

function About() {
  const aboutTexts = useText("about");
  return (
    <div className="py-24 sm:py-32">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2">
          <div className="lg:pr-8 lg:pt-4">
            <div className="lg:max-w-lg">
              <h2 className="text-base font-semibold leading-7">
                {aboutTexts.aboutSub}
              </h2>
              <p className="mt-2 text-4xl font-bold tracking-tight sm:text-6xl">
                {aboutTexts.aboutName}
              </p>
              <p className="mt-6 text-lg leading-8">{aboutTexts.aboutTxt1}</p>
              <p className="mt-6 text-lg leading-8 text-gray-600">
                {aboutTexts.aboutTxt2}
              </p>
              <p className="mt-6 text-lg leading-8 text-gray-600">
                {aboutTexts.aboutTxt3}
              </p>
            </div>
          </div>
          <div className="grid items-center justify-center">
            <img
              src={banner}
              alt="Product screenshot"
              className="rounded-xl  drop-shadow-2xl ring-1 ring-gray-400/10"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default About;
