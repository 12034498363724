import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { AuthProvider } from "./utils/AuthContext"; // Import the AuthProvider

// Components
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";

// Pages
import Home from "./pages/Home";
import About from "./pages/About";
import Menu from "./pages/Menu";
import Reservations from "./pages/Reservations";
import OrderOnline from "./pages/OrderOnline";
import Auth from "./pages/Auth";
import Register from "./pages/Register";
import Profile from "./pages/Profile";
import Photographer from "./pages/Photographer";
import "./firebase";
import { LanguageProvider } from "./utils/LanguageContext";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import DropdownAlert from "./components/Dropdownalert";
import { setDropDown } from "./common/Dropdown";

const client = new QueryClient();

const App = () => {
  return (
    <QueryClientProvider client={client}>
      <LanguageProvider>
        <AuthProvider>
          <Router>
            <Navbar />
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/about" element={<About />} />
              <Route path="/menu" element={<Menu />} />
              <Route path="/reservations" element={<Reservations />} />
              <Route path="/order-online" element={<OrderOnline />} />
              <Route path="/auth" element={<Auth />} />
              <Route path="/register" element={<Register />} />
              <Route path="/profile" element={<Profile />} />
              <Route path="/photographer" element={<Photographer />} />
            </Routes>
            <Footer />
          </Router>
          <DropdownAlert ref={(ref) => setDropDown(ref)} />
        </AuthProvider>
      </LanguageProvider>
    </QueryClientProvider>
  );
};

export default App;
