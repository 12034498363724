import React, { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import banner from "../assets/banner.webp";
import { useAuth } from "../utils/AuthContext";
import { useText } from "../utils/LanguageContext";
import Input from "../components/Input";
import { displayError } from "../common/Dropdown";

function Auth() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const { login } = useAuth();
  const navigate = useNavigate();
  const authTexts = useText("auth");

  async function submit() {
    setLoading(true);
    try {
      const res = await login(email, password);

      if (res) {
        setError(false); // Clear error on successful login
        navigate("/");
      }
    } catch (error) {
      displayError(authTexts.error);
      setError(true); // Set error on failed login
    } finally {
      setLoading(false);
    }
  }

  return (
    <div className="flex flex-col md:flex-row items-center justify-center w-full h-screen bg-white">
      <div className="flex flex-1 justify-center items-center h-full w-full px-4 sm:px-6 md:px-8 lg:px-12">
        <div className="max-w-lg w-full">
          <h1 className="text-2xl font-bold mb-6 text-gray-800 text-center">
            {authTexts.login}
          </h1>
          <form className="bg-white w-full">
            <div className="mb-4 w-full">
              <label className="block text-sm font-medium text-gray-700">
                {authTexts.email}
              </label>
              <Input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700">
                {authTexts.password}
              </label>
              <input
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className={`mt-1 block w-full px-4 py-2 bg-gray-50 border ${
                  error ? "border-red-500" : "border-gray-300"
                } rounded-md shadow-sm focus:outline-none focus:border-yellow-500 focus:ring focus:ring-yellow-500 focus:ring-opacity-50`}
              />
            </div>
            <div
              onClick={submit}
              className="flex justify-center items-center block w-full px-4 py-2 text-sm font-medium text-white bg-yellow-500 rounded-md hover:bg-yellow-600 focus:outline-none focus:bg-yellow-600 transition duration-300 cursor-pointer"
            >
              {loading ? (
                <svg
                  className="animate-spin h-5 w-5 text-white"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    strokeWidth="4"
                  ></circle>
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C6.48 0 2 4.48 2 10h2zM12 22a8 8 0 008-8h-2a6 6 0 01-6 6v2z"
                  ></path>
                </svg>
              ) : (
                authTexts.sign
              )}
            </div>
            {error && (
              <div className="mt-4 text-sm text-red-500 text-center">
                {authTexts.error}
              </div>
            )}
            <div className="mt-4 text-center">
              <NavLink
                to="/register"
                className="text-sm text-yellow-500 hover:underline"
              >
                {authTexts.noAcc}
              </NavLink>
            </div>
          </form>
        </div>
      </div>
      <div className="relative flex-1 h-full hidden md:flex">
        <img
          src={banner}
          alt="Descriptive Alt Text"
          className="absolute right-0 top-1/2 transform -translate-y-1/2 h-full w-auto object-cover"
          style={{ clipPath: "circle(90% at 100% 50%)" }}
        />
      </div>
    </div>
  );
}

export default Auth;
