import React, { createContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { BASE_URL } from "./request";
import { displayError, displaySuccess } from "../common/Dropdown";
import { useText } from "./LanguageContext";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [authToken, setAuthToken] = useState(
    localStorage.getItem("authToken") || null
  );

  const texts = useText("auth");

  useEffect(() => {
    if (authToken) {
      localStorage.setItem("authToken", authToken);
    } else {
      localStorage.removeItem("authToken");
    }
  }, [authToken]);

  const login = async (email, password) => {
    const url = `${BASE_URL}users/login`;
    const headers = {
      "Content-Type": "application/json",
    };
    const body = JSON.stringify({
      email: email,
      password: password,
    });

    try {
      const response = await fetch(url, {
        method: "POST",
        headers: headers,
        body: body,
      });

      if (!response.ok) {
        throw new Error("Network response was not ok " + response.statusText);
      }

      const data = await response.json();
      if (data?.token) {
        localStorage.setItem("authToken", data.token);
        setAuthToken(data.token);
        displaySuccess(texts.success);

        return true;
      } else {
        displayError(texts.error);
        return false;
      }
    } catch (error) {
      displayError(texts.error);
      console.error("There was a problem with the login request:", error);
      return false;
    }
  };

  const logout = () => {
    setAuthToken(null);
  };

  return (
    <AuthContext.Provider value={{ authToken, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => React.useContext(AuthContext);
