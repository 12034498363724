import React, { useState, useEffect } from "react";
import {
  UserCircleIcon,
  PhoneIcon,
  BuildingOffice2Icon,
  GlobeAltIcon,
  CurrencyDollarIcon,
  EnvelopeIcon,
  InformationCircleIcon,
  PencilSquareIcon,
  PhotoIcon,
} from "@heroicons/react/24/solid";
import request from "../utils/request";
import { useText } from "../utils/LanguageContext";
import placeholder from "../assets/placeholder.jpg";
import logoAnimation from "../assets/S.mp4";
import { displayError, displaySuccess } from "../common/Dropdown";

const Profile = () => {
  const [profile, setProfile] = useState({
    fullName: "",
    mobileNumber: "",
    photo: "",
    about: "",
    portfolio: [],
    eventType: [],
    location: {
      city: "",
      state: "",
      country: "",
    },
    priceRange: {
      wedding: 1,
      family: 1,
      birthday: 1,
      sports: 1,
      tourist: 1,
      advertisement: 1,
      personal: 1,
      other: 1,
    },
    email: "",
    active: true,
  });

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const profileTexts = useText("profile");

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const data = await request("users/get/me", "GET");
        setProfile(data.data);
        setLoading(false);
        console.log(data.data);
      } catch (error) {
        setError("Failed to fetch profile");
        setLoading(false);
      }
    };

    fetchProfile();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setProfile({
      ...profile,
      [name]: value,
    });
  };

  const handleLocationChange = (e) => {
    const { name, value } = e.target;
    setProfile({
      ...profile,
      location: {
        ...profile.location,
        [name]: value,
      },
    });
  };

  const handleSliderChange = (e) => {
    const { name, value } = e.target;
    setProfile({
      ...profile,
      priceRange: {
        ...profile.priceRange,
        [name]: parseInt(value),
      },
    });
  };

  const handleToggleActive = () => {
    setProfile((prevProfile) => ({
      ...prevProfile,
      active: !prevProfile.active,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const res = await request("users/updateme", "PATCH", profile);
      console.log(res);
      displaySuccess("Profile Updated successfully");
    } catch (error) {
      displayError(error);
    }
  };

  const handlePortfolioUpload = (e) => {
    const file = e.target?.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        const updatedPortfolio = [...profile.portfolio, reader.result];
        setProfile({ ...profile, portfolio: updatedPortfolio });
      };
      reader.readAsDataURL(file);
    }
  };

  const handlePhotoChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setProfile({ ...profile, photo: reader.result });
      };
      reader.readAsDataURL(file);
    }
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-screen bg-white">
        <video className="w-32 h-32" src={logoAnimation} autoPlay loop muted />
      </div>
    );
  }

  if (error) {
    return <div className="text-center mt-8 text-red-500">{error}</div>;
  }

  return (
    <div className="min-h-screen bg-gray-50 flex flex-col items-center py-8 sm:py-12 px-4">
      <div className="w-full max-w-7xl p-8 bg-white rounded-lg shadow-md">
        <h1 className="text-4xl font-bold text-center text-yellow-500 mb-12">
          <UserCircleIcon className="inline-block h-8 w-8 mr-2" />
          Profile
        </h1>
        <div className="flex flex-col md:flex-row md:space-x-12">
          <div className="flex flex-col items-center space-y-6 md:w-1/3">
            <img
              src={profile.photo || placeholder}
              alt="Profile"
              className="w-48 h-48 object-cover rounded-full border-4 border-yellow-500 shadow-lg"
            />
            <input
              type="file"
              accept="image/*"
              onChange={handlePhotoChange}
              className="block w-full text-sm text-gray-500 file:mr-4 file:py-2 file:px-4 file:rounded-full file:border-0 file:text-sm file:font-semibold file:bg-yellow-500 file:text-white hover:file:bg-yellow-600"
            />
            <div className="flex items-center space-x-3">
              <label className="text-gray-700">
                Activate Photographer Account
              </label>
              <input
                type="checkbox"
                name="active"
                checked={profile.active}
                onChange={handleToggleActive}
                className="toggle-checkbox rounded text-yellow-500 focus:ring-0"
              />
            </div>
          </div>
          <form
            onSubmit={handleSubmit}
            className="flex-grow space-y-8 mt-8 md:mt-0 md:w-2/3"
          >
            <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
              <div>
                <label className="block text-gray-700">
                  <UserCircleIcon className="inline-block h-6 w-6 mr-2" />
                  {profileTexts.fullname}
                </label>
                <input
                  type="text"
                  name="fullName"
                  value={profile.fullName}
                  onChange={handleChange}
                  className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-yellow-500"
                />
              </div>
              <div>
                <label className="block text-gray-700">
                  <PhoneIcon className="inline-block h-6 w-6 mr-2" />
                  {profileTexts.mobile}
                </label>
                <input
                  type="text"
                  name="mobileNumber"
                  value={profile.mobileNumber}
                  onChange={handleChange}
                  className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-yellow-500"
                />
              </div>
              <div>
                <label className="block text-gray-700">
                  <BuildingOffice2Icon className="inline-block h-6 w-6 mr-2" />
                  {profileTexts.city}
                </label>
                <input
                  type="text"
                  name="city"
                  value={profile.location.city}
                  onChange={handleLocationChange}
                  className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-yellow-500"
                />
              </div>

              <div>
                <label className="block text-gray-700">
                  <GlobeAltIcon className="inline-block h-6 w-6 mr-2" />
                  {profileTexts.country}
                </label>
                <input
                  type="text"
                  name="country"
                  value={profile.location.country}
                  onChange={handleLocationChange}
                  className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-yellow-500"
                />
              </div>
            </div>

            <div>
              <label className="block text-gray-700">
                <InformationCircleIcon className="inline-block h-6 w-6 mr-2" />
                {profileTexts.about}
              </label>
              <textarea
                name="about"
                value={profile.about}
                onChange={handleChange}
                className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-yellow-500"
              />
            </div>
            <div>
              <label className="block text-gray-700">
                <EnvelopeIcon className="inline-block h-6 w-6 mr-2" />
                {profileTexts.email}
              </label>
              <input
                type="email"
                name="email"
                value={profile.email}
                onChange={handleChange}
                readOnly
                className="w-full px-4 py-2 border rounded-lg bg-gray-100 cursor-not-allowed"
              />
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
              {Object.keys(profile?.priceRange || {}).map((key) => (
                <div key={key} className="w-full">
                  <label className="block text-gray-700">
                    <CurrencyDollarIcon className="inline-block h-6 w-6 mr-2" />
                    {profileTexts[key]}:
                  </label>
                  <div className="flex items-center space-x-4">
                    <input
                      type="range"
                      name={key}
                      min="0"
                      max="10000"
                      value={profile.priceRange[key]}
                      onChange={handleSliderChange}
                      className="w-full accent-yellow-500 focus:ring-yellow-500"
                    />
                    <span className="text-yellow-500">
                      ₾ {profile.priceRange[key] || 0}
                    </span>
                  </div>
                </div>
              ))}
            </div>
            <button
              type="submit"
              className="w-full py-3 bg-yellow-500 text-white font-bold rounded-lg hover:bg-yellow-600 flex items-center justify-center"
            >
              <PencilSquareIcon className="inline-block h-6 w-6 mr-2" />
              {profileTexts.update}
            </button>
          </form>
        </div>
        <div className="mt-12 w-full max-w-7xl">
          <h2 className="text-2xl font-bold text-yellow-500 mb-6">
            {profileTexts.portfolio}
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            {profile.portfolio?.map((item, index) => (
              <div key={index} className="bg-gray-100 p-4 rounded-lg shadow-sm">
                <img
                  src={item || placeholder}
                  alt={`Portfolio ${index}`}
                  className="w-full h-40 object-cover rounded-lg"
                />
              </div>
            ))}
            <label
              htmlFor="portfolio-upload"
              className="flex items-center justify-center w-full h-40 bg-gray-200 border-2 border-dashed border-gray-300 rounded-lg cursor-pointer hover:bg-gray-100"
            >
              <PhotoIcon className="h-12 w-12 text-gray-400" />
              <input
                id="portfolio-upload"
                type="file"
                accept="image/*"
                onChange={handlePortfolioUpload}
                className="hidden"
              />
            </label>
          </div>
          <button
            onClick={handleSubmit}
            className="w-full py-3 mt-6 bg-yellow-500 text-white font-bold rounded-lg hover:bg-yellow-600 flex items-center justify-center"
          >
            <PencilSquareIcon className="inline-block h-6 w-6 mr-2" />
            {profileTexts.updatePortfolio}
          </button>
        </div>
      </div>
    </div>
  );
};

export default Profile;
