import React, { useState } from "react";
import { Transition } from "@headlessui/react";
import {
  CheckCircleIcon,
  XCircleIcon,
  ClockIcon,
  InformationCircleIcon,
  PaperAirplaneIcon,
  InboxIcon,
} from "@heroicons/react/24/solid";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import request from "../utils/request";

export default function Reservation() {
  const [activeTab, setActiveTab] = useState("received");
  const [selectedRequest, setSelectedRequest] = useState(null);
  const queryClient = useQueryClient();

  // Fetch pending bookings
  const { data: pendingData, isLoading: pendingLoading } = useQuery({
    queryKey: ["pendingBookings"],
    queryFn: async () => {
      const res = await request("booking/pending", "GET");
      return res.data;
    },
  });

  // Fetch incoming bookings
  const { data: incomingData, isLoading: incomingLoading } = useQuery({
    queryKey: ["incomingBookings"],
    queryFn: async () => {
      const res = await request("booking/incoming", "GET");
      return res.data;
    },
  });

  // Mutation to answer a booking request (accept/reject)
  const answerBookingMutation = useMutation({
    mutationFn: async ({ id, status }) => {
      return await request(`booking/${id}`, "PATCH", { status });
    },
    onSuccess: () => {
      queryClient.invalidateQueries(["pendingBookings"]);
      queryClient.invalidateQueries(["incomingBookings"]);
    },
  });

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  const openModal = (request) => {
    setSelectedRequest(request);
  };

  const closeModal = () => {
    setSelectedRequest(null);
  };

  const handleAccept = (id) => {
    answerBookingMutation.mutate({ id, status: "accepted" });
    closeModal();
  };

  const handleReject = (id) => {
    answerBookingMutation.mutate({ id, status: "rejected" });
    closeModal();
  };

  const getStatusColor = (status) => {
    switch (status) {
      case "accepted":
        return "text-green-500";
      case "rejected":
        return "text-red-500";
      case "pending":
        return "text-yellow-500";
      default:
        return "text-gray-500";
    }
  };

  const renderBooking = (booking, isReceived) => (
    <div
      key={booking._id}
      className="bg-gray-100 p-6 rounded-xl shadow-md flex items-center justify-between transition-all transform hover:scale-105 hover:shadow-xl max-w-4xl mx-auto"
    >
      <InboxIcon className="h-8 w-8 text-yellow-500" />
      <div className="ml-6 flex-1">
        <h2 className="text-lg font-bold">{booking.eventType}</h2>
        <p className="text-sm text-gray-600">
          <span className="font-semibold">Time:</span>{" "}
          {new Date(booking.eventDate).toLocaleString()}
        </p>
        <p className="text-sm text-gray-600">
          <span className="font-semibold">Location:</span>{" "}
          {booking.eventLocation}
        </p>
        <p className="text-sm text-gray-600">
          <span className="font-semibold">Status:</span> {booking.status}
        </p>
      </div>
      <div className="flex items-center space-x-4">
        {isReceived && booking.status === "pending" ? (
          <>
            <button
              className="text-green-500 hover:text-green-700 transition"
              onClick={() => openModal({ ...booking, action: "accept" })}
              title="Accept"
            >
              <CheckCircleIcon className="h-6 w-6" />
            </button>
            <button
              className="text-red-500 hover:text-red-700 transition"
              onClick={() => openModal({ ...booking, action: "reject" })}
              title="Reject"
            >
              <XCircleIcon className="h-6 w-6" />
            </button>
          </>
        ) : (
          <span className={getStatusColor(booking.status)}>
            {booking.status.charAt(0).toUpperCase() + booking.status.slice(1)}
          </span>
        )}
        <button
          className="text-blue-500 hover:text-blue-700 transition"
          onClick={() => openModal(booking)}
          title="View Details"
        >
          <InformationCircleIcon className="h-6 w-6" />
        </button>
      </div>
    </div>
  );

  return (
    <div className="py-24 sm:py-32 px-4 bg-white min-h-screen">
      {/* Tabs */}
      <div className="flex justify-center mb-8">
        <button
          className={`flex items-center px-4 py-2 mx-2 rounded-full transition-all transform hover:scale-105 ${
            activeTab === "sent"
              ? "bg-yellow-500 text-white shadow-lg"
              : "bg-gray-200 shadow"
          }`}
          onClick={() => handleTabChange("sent")}
        >
          <PaperAirplaneIcon className="h-5 w-5 mr-2" />
          Sent Requests
        </button>
        <button
          className={`flex items-center px-4 py-2 mx-2 rounded-full transition-all transform hover:scale-105 ${
            activeTab === "received"
              ? "bg-yellow-500 text-white shadow-lg"
              : "bg-gray-200 shadow"
          }`}
          onClick={() => handleTabChange("received")}
        >
          <InboxIcon className="h-5 w-5 mr-2" />
          Received Requests
        </button>
      </div>

      {/* Received Requests */}
      <Transition
        show={activeTab === "received"}
        enter="transition-opacity duration-300"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity duration-200"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
        className="mt-8 space-y-4"
      >
        {incomingLoading ? (
          <p>Loading...</p>
        ) : (
          incomingData?.map((booking) => renderBooking(booking, true))
        )}
      </Transition>

      {/* Sent Requests */}
      <Transition
        show={activeTab === "sent"}
        enter="transition-opacity duration-300"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity duration-200"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
        className="mt-8 space-y-4"
      >
        {pendingLoading ? (
          <p>Loading...</p>
        ) : (
          pendingData?.map((booking) => renderBooking(booking, false))
        )}
      </Transition>

      {/* Modal */}
      {selectedRequest && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="bg-white rounded-3xl shadow-lg max-w-lg w-full p-6 transition-all transform">
            <h2 className="text-xl font-bold mb-4">
              {selectedRequest.eventType}
            </h2>
            <p className="mb-2 text-gray-800">
              <span className="font-semibold">Time:</span>{" "}
              {new Date(selectedRequest.eventDate).toLocaleString()}
            </p>
            <p className="mb-2 text-gray-800">
              <span className="font-semibold">Location:</span>{" "}
              {selectedRequest.eventLocation}
            </p>
            <p className="mb-4 text-gray-800">
              <span className="font-semibold">Description:</span>{" "}
              {selectedRequest.description || "No description provided."}
            </p>
            <div className="flex justify-end space-x-4">
              {activeTab === "received" &&
              selectedRequest.status === "pending" ? (
                <>
                  <button
                    className="bg-yellow-500 text-white px-4 py-2 rounded-full shadow-md transition-all transform hover:scale-105"
                    onClick={() =>
                      selectedRequest.action === "accept"
                        ? handleAccept(selectedRequest._id)
                        : handleReject(selectedRequest._id)
                    }
                  >
                    {selectedRequest.action === "accept" ? "Accept" : "Reject"}
                  </button>
                  <button
                    className="bg-blue-500 text-white px-4 py-2 rounded-full shadow-md transition-all transform hover:scale-105"
                    onClick={closeModal}
                  >
                    Close
                  </button>
                </>
              ) : (
                <button
                  className="bg-blue-500 text-white px-4 py-2 rounded-full shadow-md transition-all transform hover:scale-105"
                  onClick={closeModal}
                >
                  Close
                </button>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
