const textsJson = {
  eng: {
    about: {
      aboutSub: "Get to know",
      aboutName: "About Us",
      aboutTxt1:
        "Welcome to Snaps Smile Photography, your trusted partner in freelance photography! Founded by the visionary photographers team, we bring together a network of talented freelance photographers each with a unique style and a shared passion for capturing life's most memorable moments. Whether it's a family gathering, a corporate event, or the quiet beauty of everyday life, our team is ready to capture it with creativity and flair.",
      aboutTxt2:
        "At Snaps Smile, we believe that great photography tells a story, and our freelancers are skilled storytellers. They are equippedwith the latest in photography technology and bring apersonalized touch to every project, ensuring that your specific needs are met with the highest standards of quality and service. Connect with us to find the perfect photographer for your next project and let us help you preserve your special moments in stunning detail.",
      aboutTxt3:
        '"Snap Smile" project implemented by non comercial legal entities "Youth Foundation for development", financed by Zugdidi Municipality City Hall, developed with the support of the EU project #EU4Culture within the framework of the culture development strategy of Zugdidi',
    },
    auth: {
      login: "Login",
      email: "Email",
      password: "Password",
      sign: "Sign In",
      error: "Invalid email or password!",
      noAcc: "Don't have an account?",
      success: "Successfully logged in",
    },
    home: {
      name: "SnapSmile",
      subtitle: "Capturing Moments, Crafting Memories",
      book: "Book a Photographer",
      top: "Top photographers",
      all: "View all",
      txt: "Simplify your photographer search process with our efficient booking platform designed to save you time and to connect you with the best professionals in just a few steps",
    },
    menu: {
      current: "Currently Available",
      search: "Search by name",
      location: "Select Location",
      time: "Select Available Time",
      any: "Any",
      tbilisi: "Tbilisi",
      kakheti: "Kakheti",
      mcxeta: "Mcxeta-mtianeti",
      imereti: "Imereti",
      samegrelo: "Samegrelo",
      racha: "Racha",
      qvemoqartli: "qvemo qartli",
      shidaqartli: "shida qartli",
      weekdays: "Weekdays",
      weekends: "Weekends",
      evening: "after hours",
      photograph: "photograph",
      videograph: "videograph",
      wedding: "wedding",
      birthday: "birthday",
      family: "family",
      sports: "sports",
      advertisement: "advertisement",
      personal: "personal",
      corporate: "corporate",
      other: "other",
      tourist: "tourist",
      georgian: "georgian",
      english: "english",
      russian: "russian",
      indoors: "indoors",
      outdoors: "outdoors",
    },
    photographer: {
      error: "Photographer not found",
      contact: "Contact",
      mobile: "Mobile:",
      email: "Email:",
      range: "Price Range",
      fail: "Booking failed",
      success: "Booking successful",
    },
    profile: {
      fullname: "Full Name:",
      mobile: "Mobile Number:",
      city: "City:",
      country: "Country:",
      min: "Price Range (Min):",
      max: "Price Range (Max):",
      about: "About:",
      email: "Email:",
      update: "Update Profile",
      portfolio: "Portfolio",
      add: "Add to Portfolio",
      wedding: "Wedding",
      birthday: "Birthday",
      family: "Family",
      sports: "Sports",
      advertisement: "Advertisement",
      personal: "Personal",
      corporate: "Corporate",
      other: "Other",
      tourist: "Tourist",
    },
    register: {
      next: "Next",
      back: "Back",
      choose: "Choose Image",
      about: "About",
      portfolio: "Portfolio",
      event: "Event Type Prices",
      wedding: "Wedding",
      birthday: "Birthday",
      family: "Family",
      corporate: "Corporate",
      other: "other",
      range: "Price Range",
      register: "Register",
      noAcc: "Have an account?",
      started: "Let's Get Started",
      create: "Create Your Account",
      almost: "Almost There",
      first: "First Name",
      last: "Last Name",
      email: "Email",
      phone: "Phone Number",
      password: "Password",
      confirm: "Confirm Password",
      city: "city",
      country: "country",
      failed_register: "Failed to register",
      success_register: "Successfully registered",
    },
    navbar: {
      openmenu: "View All",
      viewprofile: "View profile",
      logout: "Logout",
      login: "Login",
      home: "Home",
      about: "About",
      photographer: "Photographers",
      reservations: "Reservations",
    },
  },
  geo: {
    about: {
      aboutSub: " ",
      aboutName: "ჩვენ შესახებ",
      aboutTxt1:
        "კეთილი იყოს თქვენი მობრძანება Snaps Smile Photography-ში, ჩვენ ვაქცვთ თქვენი ცხოვრების მომენტებს უფრო დასამახსოვრებელს! Snap Smile არის გამოცდილი ფოტოგრაფების გუნდის მიერ დაარსებული პლატფორმა, რომელიც აერთიანებს განსხვავებული სტილის და პრეფერენციების ფოტო და ვიდეოგრაფების მომსახურებას. პლატფორმის შექმნის მოტივი არის რომ გაგმიარტივოთ ნავიგაცია და გამჭვირვალე ინფორმცია მოგაწოდოთ მომსახურების შესახებ, თქვენ უმარტივესად შეძლებთ თქვენთვის შესაფერისი ფოტოგრაფის პოვნას პორტფელის, გადაღების სტილის, ლოკაციის, ბიუჯეტის, საკომუნიკაციო ენის, გამოცდილებისა და სხვა მახასიათებლების მიხედვით.",
      aboutTxt2:
        "Snaps Smile დაგაზოგინებთ დროსა და ფინანსებს. ჩვენთან დარეგისტრირებული ფოტოგრაფები კი უზრუნველყოფენ თქვენი სპეციფიკური მოთხოვნილებების დაკმაყოფილებას ხარისხისა და მომსახურების უმაღლესი სტანდარტებით. ეს არის სივრცე, რომელიც გთავაზობთ სწრაფ, მარტივ და ხარსხიანი მომსახურების სერვისს ფოტოგრაფიის სფეროში",
      aboutTxt3:
        "პროექტი „Snap Smile“ რომელსაც ახორციელებს ააიპ ,,ფონდი ახალგაზრდების განვითარებისთვის“, დაფინანსებულია ზუგდიდის მუნიციპალიტეტის მერიის მიერ, ევროკავშირის პროექტის #EU4Culture მხარდაჭერით შემუშავებული ზუგდიდის კულტურის განვითარების სტრატეგიის ფარგლებში",
    },
    auth: {
      login: "შესვლა",
      email: "ელფოსტა",
      password: "პაროლი",
      sign: "შესვლა",
      error: "არასწორი ელფოსტა ან პაროლი!",
      noAcc: "არ გაქვს ანგარიში?",
      success: "შესვლა წარმატებით",
    },
    home: {
      name: "სნეპსმაილი",
      subtitle: "აქციე შენი ცხოვრების მომენტები უფრო დასამახსოვრებელი",
      book: "დაჯავშნე ფოტოგრაფი",
      top: "საუკეთესო ფოტოგრაფები",
      all: "ყველას ნახვა",
      txt: "გაამარტივეთ ფოტოგრაფის ძიების პროცესი ჩვენი ეფექტური დაჯავშნის პლატფორმით, რომელიც შექმნილია თქვენი დროის დაზოგვისა და საუკეთესო პროფესიონალებთან დასაკავშირებლად მხოლოდ რამდენიმე მოძრაობით",
    },
    menu: {
      current: "ახლა ხელმისაწვდომი",
      search: "მოძებნე სახელით",
      location: "აირჩიე ლოკაცია",
      time: "აირჩიე დრო",
      any: "ნებისმიერი",
      tbilisi: "თბილისი",
      kakheti: "კახეთი",
      mcxeta: "მცხეთა-მთიანეთი",
      imereti: "იმერეთი",
      samegrelo: "სამეგრელო",
      racha: "რაჭა",
      qvemoqartli: "ქვემო ქართლი",
      shidaqartli: "შიდა ქართლი",
      weekdays: "შუა-კვირა",
      weekends: "შაბათ-კვირა",
      evening: "სამუშაო საათების მერე",
      photograph: "ფოტოგრაფი",
      videograph: "ვიდეოგრაფი",
      wedding: "ქორწილი",
      birthday: "დაბადების დღე",
      family: "ოჯახური",
      sports: "სპორტი",
      advertisement: "რეკლამა",
      personal: "პერსონალური",
      corporate: "კორპორატიული",
      other: "სხვა",
      tourist: "ტურისტი",
      georgian: "ქართული",
      english: "ინგლისური",
      russian: "რუსული",
      indoors: "შიდა სივრცე",
      outdoors: "გარე სივრცე",
    },
    photographer: {
      error: "ფოტოგრაფი ვერ მოიძებნა",
      contact: "კონტაქტი",
      mobile: "ტელეფონი:",
      email: "ელფოსტა:",
      range: "ფასი:",
      fail: "მოხდა შეცდომა",
      success: "დაჯავშნა წარმატებულია",
    },
    profile: {
      fullname: "სრული სახელი:",
      mobile: "ტელეფონის ნომერი:",
      city: "ქალაქი:",
      country: "ქვეყანა:",
      min: "ფასი მინიმალური:",
      max: "ფასი მაქსიმალური:",
      about: "შესახებ:",
      email: "ელფოსტა:",
      update: "განაახლე",
      portfolio: "პორტფოლიო",
      add: "დაამატე პორფოლიოში",
      wedding: "ქორწილი",
      birthday: "დაბადების დღე",
      family: "ოჯახური",
      sports: "სპორტი",
      advertisement: "რეკლამა",
      personal: "პერსონალური",
      corporate: "კორპორატიული",
      other: "სხვა",
      tourist: "ტურისტი",
    },
    register: {
      next: "შემდეგი",
      back: "უკან",
      choose: "აირჩიე სურათი",
      about: "შესახებ ",
      portfolio: "პორტფოლიო",
      event: "ფასები ჯავშნის ტიპის მიხედვით",
      wedding: "ქორწილი",
      birthday: "დაბადების დღე",
      family: "ოჯახური",
      sports: "სპორტი",
      advertisement: "რეკლამა",
      personal: "პერსონალური",
      corporate: "კორპორატიული",
      other: "სხვა",
      range: "ფასი",
      register: "რეგისტრაცია",
      noAcc: "გაქვს ანგარიში?",
      started: "დაწყება",
      create: "შექმენი ექაუნთი",
      almost: "თითქმის",
      first: "სახელი",
      last: "გვარი",
      email: "ელფოსტა",
      phone: "ტელეფონის ნომერი",
      password: "პაროლი",
      confirm: "გაამეორე პაროლი",
      city: "ქალაქი",
      country: "ქვეყანა",
      failed_register: "მოხდა შეცდომა!",
      success_register: "რეგისტრაცია წარმატებით შესრულდა",
    },
    navbar: {
      openmenu: "ვრცლად",
      viewprofile: "პროფილი",
      logout: "გამოსვლა",
      login: "შესვლა",
      home: "მთავარი",
      about: "ჩვენ შესახებ",
      photographer: "ფოტოგრაფები",
      reservations: "რეზერვაციები",
    },
  },
};

export default textsJson;
