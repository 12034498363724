import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import {
  MagnifyingGlassIcon,
  MapPinIcon,
  ClockIcon,
  CameraIcon,
  FilmIcon,
  LanguageIcon,
  BuildingOfficeIcon,
} from "@heroicons/react/24/outline";
import defPhoto from "../assets/default.jpg";
import logoAnimation from "../assets/S.mp4";
import { useText } from "../utils/LanguageContext";
import { Slider } from "@mui/material";
import { styled } from "@mui/system";
import { useQuery } from "@tanstack/react-query";
import { getPhotographers } from "../services";
import Loading from "../components/Loading";

const YellowSlider = styled(Slider)({
  color: "#FFD700",
});

export default function Example() {
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedLocation, setSelectedLocation] = useState("Any");
  const [selectedAvailableTime, setSelectedAvailableTime] = useState("Any");
  const [selectedPhotographType, setSelectedPhotographType] = useState("Any");
  const [selectedEventFormat, setSelectedEventFormat] = useState("Any");
  const [selectedLanguage, setSelectedLanguage] = useState("Any");
  const [priceRange, setPriceRange] = useState([0, 1000]);
  const [selectedSessionType, setSelectedSessionType] = useState("Any");
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const LIMIT = 9;
  const menuTexts = useText("menu");

  const locations = [
    menuTexts.any,
    menuTexts.tbilisi,
    menuTexts.kakheti,
    menuTexts.mcxeta,
    menuTexts.imereti,
    menuTexts.samegrelo,
    menuTexts.racha,
    menuTexts.qvemoqartli,
    menuTexts.shidaqartli,
  ];
  const availableTimes = [
    menuTexts.any,
    menuTexts.weekdays,
    menuTexts.weekends,
    menuTexts.evening,
  ];
  const photographTypes = [
    menuTexts.any,
    menuTexts.photograph,
    menuTexts.videograph,
  ];
  const eventFormats = [
    menuTexts.any,
    menuTexts.wedding,
    menuTexts.family,
    menuTexts.birthday,
    menuTexts.sports,
    menuTexts.tourist,
    menuTexts.advertisement,
    menuTexts.personal,
  ];
  const languages = ["Any", "Georgian", "English", "Russian"];
  const sessionTypes = ["Any", "Studio", "Outdoors"];

  const getQueryStr = () => {
    let queryStr = `page=${String(page)}&limit=${String(LIMIT)}`;

    if (searchTerm) {
      queryStr += `&fullName=${encodeURIComponent(searchTerm)}`;
    }

    if (selectedLocation && selectedLocation !== "Any") {
      queryStr += `&location.city=${encodeURIComponent(selectedLocation)}`;
    }

    if (selectedEventFormat && selectedEventFormat !== "Any") {
      queryStr += `&eventType=${encodeURIComponent(selectedEventFormat)}`;
    }

    if (selectedLanguage && selectedLanguage !== "Any") {
      queryStr += `&language=${encodeURIComponent(selectedLanguage)}`;
    }

    if (priceRange && (priceRange[0] !== 0 || priceRange[1] !== 1000)) {
      queryStr += `&priceRangeMin=${priceRange[0]}&priceRangeMax=${priceRange[1]}`;
    }

    if (selectedSessionType && selectedSessionType !== "Any") {
      queryStr += `&eventType=${encodeURIComponent(selectedSessionType)}`;
    }

    return queryStr;
  };

  const { data, isLoading, refetch, isRefetching } = useQuery({
    queryKey: ["photographers", page],
    queryFn: async () => {
      const response = await getPhotographers(getQueryStr());

      // response.filter((photographer) => photographer.active === true);
      const filteredResult = response.data.filter(
        (photographer) => photographer.active === true
      );

      setTotalPages(Math.ceil(filteredResult.length / LIMIT));
      return filteredResult;
    },
  });

  const dishes = (data || []).map((item) => ({
    id: item._id,
    src: item.photo || defPhoto,
    title: item.fullName,
    description: item.about,
    price: item.priceRange ? item.priceRange.min : "N/A",
    phone: item.mobileNumber,
    location: item.location
      ? `${item.location.city}, ${item.location.state}`
      : "N/A",
    availableTime: item.eventType?.join(", ") || "N/A",
    photographType: item.photographType || "N/A",
    eventFormat: item.eventFormat || "N/A",
    language: item.language || [],
    sessionType: item.sessionType || "N/A",
  }));

  const loading = isLoading || isRefetching;

  return (
    <div className="py-24 sm:py-32">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto flex justify-between lg:mx-0">
          <h2 className="text-4xl font-bold tracking-tight sm:text-6xl">
            {menuTexts.current}
          </h2>
        </div>
        <div className="mt-10 flex flex-col items-center">
          <div className="relative w-full mb-6">
            <input
              type="text"
              placeholder={menuTexts.search}
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="w-full pl-10 pr-4 py-2 border rounded-lg shadow-sm focus:outline-none active:outline-none "
            />
            <MagnifyingGlassIcon className="absolute left-3 top-3 h-5 w-5 text-gray-500" />
          </div>
        </div>
        <div className="mt-10 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
          <div className="flex items-center border p-2 rounded-lg">
            <MapPinIcon className="h-5 w-5 text-gray-500 mr-2" />
            <select
              value={selectedLocation}
              onChange={(e) => setSelectedLocation(e.target.value)}
              className="w-full border-none focus:outline-none"
            >
              {locations.map((location) => (
                <option key={location} value={location}>
                  {location}
                </option>
              ))}
            </select>
          </div>
          <div className="flex items-center border p-2 rounded-lg">
            <ClockIcon className="h-5 w-5 text-gray-500 mr-2" />
            <select
              value={selectedAvailableTime}
              onChange={(e) => setSelectedAvailableTime(e.target.value)}
              className="w-full border-none focus:outline-none"
            >
              {availableTimes.map((time) => (
                <option key={time} value={time}>
                  {time}
                </option>
              ))}
            </select>
          </div>
          <div className="flex items-center border p-2 rounded-lg">
            <CameraIcon className="h-5 w-5 text-gray-500 mr-2" />
            <select
              value={selectedPhotographType}
              onChange={(e) => setSelectedPhotographType(e.target.value)}
              className="w-full border-none focus:outline-none"
            >
              {photographTypes.map((type) => (
                <option key={type} value={type}>
                  {type}
                </option>
              ))}
            </select>
          </div>
          <div className="flex items-center border p-2 rounded-lg">
            <FilmIcon className="h-5 w-5 text-gray-500 mr-2" />
            <select
              value={selectedEventFormat}
              onChange={(e) => setSelectedEventFormat(e.target.value)}
              className="w-full border-none focus:outline-none"
            >
              {eventFormats.map((format) => (
                <option key={format} value={format}>
                  {format}
                </option>
              ))}
            </select>
          </div>
          <div className="flex items-center border p-2 rounded-lg">
            <LanguageIcon className="h-5 w-5 text-gray-500 mr-2" />
            <select
              value={selectedLanguage}
              onChange={(e) => setSelectedLanguage(e.target.value)}
              className="w-full border-none focus:outline-none"
            >
              {languages.map((language) => (
                <option key={language} value={language}>
                  {language}
                </option>
              ))}
            </select>
          </div>
          <div className="flex items-center border p-2 rounded-lg">
            <BuildingOfficeIcon className="h-5 w-5 text-gray-500 mr-2" />
            <select
              value={selectedSessionType}
              onChange={(e) => setSelectedSessionType(e.target.value)}
              className="w-full border-none focus:outline-none"
            >
              {sessionTypes.map((type) => (
                <option key={type} value={type}>
                  {type}
                </option>
              ))}
            </select>
          </div>
          <div className="w-full col-span-1 sm:col-span-2 lg:col-span-3">
            <YellowSlider
              value={priceRange}
              onChange={(e, newValue) => setPriceRange(newValue)}
              valueLabelDisplay="auto"
              min={0}
              max={1000}
            />
          </div>
        </div>

        {/* Search Button */}
        <div className="flex justify-center mt-8">
          <button
            onClick={() => {
              setPage(1); // Reset to the first page when a new search is initiated
              refetch(); // Refetch the data with the new filters
            }}
            className="px-6 py-2 bg-yellow-500 text-white rounded-md hover:bg-yellow-600"
          >
            Search
          </button>
        </div>

        <div className="mx-auto mt-10 grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 border-t border-[#495e57] pt-10 sm:mt-16 sm:pt-16 lg:mx-0 lg:max-w-none lg:grid-cols-3">
          {loading ? (
            <>
              {new Array(9).fill(null).map((_) => (
                <Loading height={700} />
              ))}
            </>
          ) : (
            <>
              {dishes.map((item) => (
                <NavLink
                  to={{
                    pathname: "/photographer",
                  }}
                  state={{ photographer: item }}
                  key={item.id}
                >
                  <div className="h-[700px] max-w-sm rounded-md overflow-hidden shadow-xl transition-transform transform hover:scale-105">
                    <img
                      className="w-full h-80 object-cover"
                      src={item.src}
                      alt="Photographer"
                    />
                    <div className="px-6 py-4">
                      <div className="font-bold text-xl mb-2">{item.title}</div>
                      <p className="">{item.description}</p>
                    </div>
                    <div className="px-6 pt-4 pb-2">
                      <p className="py-1">GEL {item.price}</p>
                      <p className="py-1">{item.phone}</p>
                      <p className="py-1">{item.location}</p>
                      <p className="py-1">{item.availableTime}</p>
                      <p className="py-1">{item.photographType}</p>
                      <p className="py-1">{item.eventFormat}</p>
                      <p className="py-1">{item.language.join(", ")}</p>
                      <p className="py-1">{item.sessionType}</p>
                    </div>
                  </div>
                </NavLink>
              ))}
            </>
          )}
        </div>
        <div className="flex justify-between items-center mt-10">
          <button
            onClick={() => setPage(page - 1)}
            disabled={page === 1}
            className="px-4 py-2 bg-gray-300 text-black rounded-md hover:bg-gray-400 disabled:bg-gray-200"
          >
            Previous
          </button>
          <span className="text-lg font-semibold">
            Page {page} of {totalPages}
          </span>
          <button
            onClick={() => setPage(page + 1)}
            disabled={page === totalPages}
            className="px-4 py-2 bg-gray-300 text-black rounded-md hover:bg-gray-400 disabled:bg-gray-200"
          >
            Next
          </button>
        </div>
      </div>
    </div>
  );
}
