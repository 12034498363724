const BASE_URL = "https://sea-lion-app-okyt3.ondigitalocean.app/"; // "http://localhost:8080/";
async function request(endpoint, method, body, isFormData = false) {
  const accessToken = localStorage.getItem("authToken");

  try {
    const url = `${BASE_URL}${endpoint}`;
    console.log(url);
    const options = {
      method: method,
      headers: {
        ...(accessToken && { Authorization: `Bearer ${accessToken}` }),
        ...(isFormData ? {} : { "Content-Type": "application/json" }),
      },
    };

    if (body) {
      options.body = isFormData ? body : JSON.stringify(body);
    }

    const response = await fetch(url, options);
    const json = await response.json();

    if (response.ok) {
      return json;
    } else {
      console.log("Server response:", response);
      console.log("Response JSON:", json);
      throw new Error(json.message || "An error occurred");
    }
  } catch (error) {
    console.error("Request failed", error);
    throw error;
  }
}

export default request;
export { BASE_URL };
