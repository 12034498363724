import React, { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import banner from "../assets/banner.webp";
import placeholder from "../assets/placeholder.jpg";
import Input from "../components/Input";
import { useText } from "../utils/LanguageContext";
import { BASE_URL } from "../utils/request";
import { displayError, displaySuccess } from "../common/Dropdown";

const StepOne = ({
  formData,
  handleChange,
  nextStep,
  registerTexts,
  errors,
}) => (
  <>
    <Input
      label={registerTexts.first}
      name="firstName"
      type="text"
      value={formData.firstName}
      onChange={handleChange}
      error={errors.firstName}
    />
    <Input
      label={registerTexts.last}
      name="lastName"
      type="text"
      value={formData.lastName}
      onChange={handleChange}
      error={errors.lastName}
    />
    <button
      type="button"
      onClick={nextStep}
      className="block w-full px-4 py-2 text-sm font-medium text-white bg-brand-orange rounded-md hover:bg-brand-orange-dark focus:outline-none focus:bg-brand-orange-dark transition duration-300"
    >
      {registerTexts.next}
    </button>
  </>
);

const StepTwo = ({
  formData,
  handleChange,
  nextStep,
  prevStep,
  registerTexts,
  errors,
}) => (
  <>
    <Input
      label={registerTexts.email}
      name="email"
      type="email"
      value={formData.email}
      onChange={handleChange}
      error={errors.email}
    />
    <Input
      label={registerTexts.phone}
      name="mobileNumber"
      type="tel"
      value={formData.mobileNumber}
      onChange={handleChange}
      error={errors.mobileNumber}
    />
    <Input
      label={registerTexts.password}
      name="password"
      type="password"
      value={formData.password}
      onChange={handleChange}
      error={errors.password}
    />
    <Input
      label={registerTexts.confirm}
      name="passwordConfirm"
      type="password"
      value={formData.passwordConfirm}
      onChange={handleChange}
      error={errors.passwordConfirm}
    />
    <button
      type="button"
      onClick={nextStep}
      className="block w-full px-4 py-2 text-sm font-medium text-white bg-brand-orange rounded-md hover:bg-brand-orange-dark focus:outline-none focus:bg-brand-orange-dark transition duration-300"
    >
      {registerTexts.next}
    </button>
    <button
      type="button"
      onClick={prevStep}
      className="mt-4 block w-full px-4 text-sm font-medium text-brand-orange bg-white rounded-md hover:bg-gray-100 focus:outline-none transition duration-300"
    >
      {registerTexts.back}
    </button>
  </>
);

const StepThree = ({
  formData,
  handleChange,
  prevStep,
  setFormData,
  handleRegister,
  registerTexts,
  loading,
}) => {
  const [profileImage, setProfileImage] = useState(placeholder);
  const fileInputRef = React.createRef();

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();

      reader.onload = () => {
        setProfileImage(reader.result);
        setFormData((prevFormData) => ({
          ...prevFormData,
          photo: reader.result,
        }));
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <>
      <div className="mb-4 text-center">
        <label className="block text-sm font-medium text-gray-700">
          Profile Picture
        </label>
        <div className="mt-1 mb-3">
          <img
            src={profileImage}
            alt="Profile"
            className="inline-block h-20 w-20 rounded-full object-cover"
          />
        </div>
        <button
          type="button"
          className="mt-2 text-sm font-medium text-brand-orange hover:underline"
          onClick={() => fileInputRef.current.click()}
        >
          {registerTexts.choose}
        </button>
        <input
          type="file"
          ref={fileInputRef}
          accept="image/*"
          className="hidden"
          onChange={handleImageChange}
        />
      </div>
      <div className="mb-4">
        <label className="block text-sm font-medium text-gray-700">
          {registerTexts.about}
        </label>
        <textarea
          name="about"
          value={formData.about}
          onChange={handleChange}
          className="mt-1 block w-full px-4 py-2 bg-gray-50 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:border-brand-orange focus:ring focus:ring-brand-orange focus:ring-opacity-50"
        />
      </div>
      <button
        type="button"
        onClick={handleRegister}
        className="block w-full px-4 py-2 text-sm font-medium text-white bg-brand-orange rounded-md hover:bg-brand-orange-dark focus:outline-none focus:bg-brand-orange-dark transition duration-300"
      >
        {loading ? (
          <svg
            className="animate-spin h-5 w-5 text-white"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
          >
            <circle
              className="opacity-25"
              cx="12"
              cy="12"
              r="10"
              stroke="currentColor"
              strokeWidth="4"
            ></circle>
            <path
              className="opacity-75"
              fill="currentColor"
              d="M4 12a8 8 0 018-8V0C6.48 0 2 4.48 2 10h2zM12 22a8 8 0 008-8h-2a6 6 0 01-6 6v2z"
            ></path>
          </svg>
        ) : (
          registerTexts.register
        )}
      </button>
      <button
        type="button"
        onClick={prevStep}
        className="mt-4 block w-full px-4 py-2 text-sm font-medium text-brand-orange bg-white rounded-md hover:bg-gray-100 focus:outline-none transition duration-300"
      >
        {registerTexts.back}
      </button>
    </>
  );
};

function Register() {
  const [step, setStep] = useState(1);

  const registerTexts = useText("register");
  const [loading, setLoading] = useState(false);

  const [formData, setFormData] = useState({
    role: "photographer",
    firstName: "",
    lastName: "",
    email: "",
    mobileNumber: "",
    password: "",
    passwordConfirm: "",
    city: "",
    state: "",
    country: "",
    about: "",
    portfolio: "",
    eventType: [],
    eventPrices: {},
    photo: null,
  });

  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
  };

  const validateStepOne = () => {
    const newErrors = {};
    if (!formData.firstName) newErrors.firstName = "First name is required.";
    if (!formData.lastName) newErrors.lastName = "Last name is required.";
    return newErrors;
  };

  const validateStepTwo = () => {
    const newErrors = {};
    if (!formData.email) newErrors.email = "Email is required.";
    if (!formData.mobileNumber)
      newErrors.mobileNumber = "Mobile number is required.";
    if (!formData.password) newErrors.password = "Password is required.";
    if (formData.password !== formData.passwordConfirm) {
      newErrors.passwordConfirm = "Passwords do not match.";
    }
    return newErrors;
  };

  const handleNextStep = () => {
    let newErrors = {};
    if (step === 1) {
      newErrors = validateStepOne();
    } else if (step === 2) {
      newErrors = validateStepTwo();
    }

    if (Object.keys(newErrors).length === 0) {
      setStep(step + 1);
    } else {
      setErrors(newErrors);
    }
  };

  const handlePrevStep = () => {
    setStep(step - 1);
  };

  const navigate = useNavigate();

  const handleRegister = async () => {
    setLoading(true);
    const url = `${BASE_URL}users/signup`;
    console.log("url: ", url);
    const payload = {
      fullName: `${formData.firstName} ${formData.lastName}`,
      mobileNumber: formData.mobileNumber,
      photo: formData.photo,
      about: formData.about,
      portfolio: formData.portfolio.split(",").map((item) => item.trim()),
      eventType: formData.eventType,
      location: {
        city: formData.city || "",
        state: formData.state,
        country: formData.country || "",
      },
      priceRange: {
        wedding: formData.eventPrices.wedding,
        birthday: formData.eventPrices.birthday,
        sports: 0,
        tourist: 0,
        advertisement: 0,
        personal: 0,
        other: 0,
      },
      email: formData.email,
      role: formData.role,
      password: formData.password,
      passwordConfirm: formData.passwordConfirm,
    };
    console.log("payload:", payload);

    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });

      console.log(response);

      if (!response.ok) {
        const errorData = await response.json();
        console.log({ errorData });
        throw new Error(errorData.message || "Something went wrong!");
      }

      const responseData = await response.json();

      if (responseData?.token) {
        navigate("/auth");
        displaySuccess(registerTexts.success_register);
      }
      setLoading(false);

      return responseData;
    } catch (error) {
      console.error("Error:", error);
      displayError(registerTexts.failed_register);
      setLoading(false);
    }
  };

  return (
    <div className="flex flex-col md:flex-row items-center justify-center w-full h-screen bg-white">
      <div className="flex flex-1 justify-center items-center h-full">
        <div className="max-w-lg w-full">
          <h1 className="text-2xl font-bold mb-6 text-gray-800 text-center">
            {step === 1
              ? registerTexts.started
              : step === 2
              ? registerTexts.createxxs
              : step === 3
              ? registerTexts.address
              : registerTexts.almost}
          </h1>
          <form className="bg-white">
            {step === 1 ? (
              <StepOne
                formData={formData}
                handleChange={handleChange}
                nextStep={handleNextStep}
                registerTexts={registerTexts}
                errors={errors}
              />
            ) : step === 2 ? (
              <StepTwo
                formData={formData}
                handleChange={handleChange}
                nextStep={handleNextStep}
                prevStep={handlePrevStep}
                registerTexts={registerTexts}
                errors={errors}
              />
            ) : (
              <StepThree
                formData={formData}
                handleChange={handleChange}
                prevStep={handlePrevStep}
                setFormData={setFormData}
                handleRegister={handleRegister}
                registerTexts={registerTexts}
                loading={loading}
              />
            )}
            <div className="mt-4 text-center">
              <NavLink
                to="/auth"
                className="text-sm text-brand-orange hover:underline"
              >
                {registerTexts.noAcc}
              </NavLink>
            </div>
          </form>
        </div>
      </div>
      <div className="relative flex-1 h-full hidden md:flex">
        <img
          src={banner}
          alt="Descriptive Alt Text"
          className="absolute right-0 top-1/2 transform -translate-y-1/2 h-full w-auto object-cover"
          style={{ clipPath: "circle(90% at 100% 50%)" }}
        />
      </div>
    </div>
  );
}

export default Register;
