// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyAvZmUoZUVvRuatt496qLy26f6Rv31pDtk",
  authDomain: "snapsmile-a2b9e.firebaseapp.com",
  projectId: "snapsmile-a2b9e",
  storageBucket: "snapsmile-a2b9e.appspot.com",
  messagingSenderId: "1022065042187",
  appId: "1:1022065042187:web:2cd5071a7120dc6b71780e",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
