import { useState } from "react";
import logo from "../assets/snaplogo.png";
import logoAnimation from "../assets/S.mp4";
import { Disclosure } from "@headlessui/react";
import {
  Bars3Icon,
  BellIcon,
  XMarkIcon,
  UserIcon,
} from "@heroicons/react/24/outline";
import { NavLink, useNavigate } from "react-router-dom";
import { useAuth } from "../utils/AuthContext";
import { useLanguage, useText } from "../utils/LanguageContext";
import { DropdownAlert } from "../common/Dropdown";

export default function Navbar() {
  const { authToken, logout } = useAuth();
  const navigate = useNavigate();
  const [isHovered, setIsHovered] = useState(false);

  const { switchLanguage, language } = useLanguage();

  const navbarTexts = useText("navbar");

  const navigation = [
    { name: navbarTexts.home, to: "/" },
    { name: navbarTexts.about, to: "/about" },
    { name: navbarTexts.photographer, to: "/menu" },
    authToken ? { name: navbarTexts.reservations, to: "/reservations" } : {},
  ];

  const signout = async () => {
    await logout();
    navigate("/");
  };

  const handleLogoClick = () => {
    navigate("/");
  };

  return (
    <Disclosure as="nav" className="shadow-md">
      {({ open }) => (
        <>
          <div className="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8">
            <div className="relative flex h-24 items-center justify-between">
              <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
                <Disclosure.Button className="relative inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                  <span className="absolute -inset-0.5" />
                  <span className="sr-only">{navbarTexts.openmenu}</span>
                  {open ? (
                    <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                  ) : (
                    <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                  )}
                </Disclosure.Button>
              </div>
              <div className="flex flex-1 items-center justify-center sm:items-stretch sm:justify-start">
                <div className="flex flex-shrink-0 items-center">
                  <div
                    onClick={handleLogoClick}
                    onMouseEnter={() => setIsHovered(true)}
                    onMouseLeave={() => setIsHovered(false)}
                    className="cursor-pointer"
                  >
                    {isHovered ? (
                      <video
                        className="h-24 w-auto"
                        src={logoAnimation}
                        autoPlay
                        loop
                        muted
                      />
                    ) : (
                      <img
                        className="h-24 w-auto transition-transform transform hover:scale-110"
                        src={logo}
                        alt="Your Company"
                      />
                    )}
                  </div>
                </div>
                <div className="hidden sm:ml-6 sm:block">
                  <div className="flex space-x-4 font-medium">
                    {navigation.map((item) => (
                      <NavLink
                        key={item.name}
                        to={item.to}
                        className={({ isActive }) => {
                          return (
                            "relative rounded-md px-3 py-12 text-sm" +
                            (isActive ? " font-bold" : " font-medium") +
                            " after:absolute after:left-0 after:bottom-10 after:h-0.5 after:bg-black after:content-[''] after:w-0 after:transition-all after:duration-300 hover:after:w-full"
                          );
                        }}
                      >
                        {item.name}
                      </NavLink>
                    ))}
                  </div>
                </div>
              </div>
              <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
                <button
                  type="button"
                  className="relative rounded-md bg-[#FAC463] duration-200 delay-100 p-2 text-[#000] hover:text-white hover:bg-black"
                  onClick={() =>
                    language === "geo"
                      ? switchLanguage("eng")
                      : switchLanguage("geo")
                  }
                >
                  <span className="absolute -inset-1.5" />

                  <h2>{language === "geo" ? "GE" : "EN"}</h2>
                </button>

                {authToken ? (
                  <>
                    <NavLink to="/profile" className="font-medium">
                      <button
                        type="button"
                        className="relative ml-3 rounded-full bg-[#FAC463] duration-200 delay-100 p-1 text-[#495e57] hover:text-white hover:bg-black"
                      >
                        <span className="absolute -inset-1.5" />
                        <span className="sr-only">
                          {navbarTexts.viewprofile}
                        </span>
                        <UserIcon className="h-6 w-6" aria-hidden="true" />
                      </button>
                    </NavLink>
                    <button onClick={signout} className="ml-3 font-medium">
                      {navbarTexts.logout}
                    </button>
                  </>
                ) : (
                  <NavLink to="/auth" className="font-medium">
                    <button className="ml-3 font-medium">
                      {navbarTexts.login}
                    </button>
                  </NavLink>
                )}
              </div>
            </div>
          </div>

          <Disclosure.Panel className="sm:hidden">
            <div className="space-y-1 px-2 pb-3 pt-2">
              {navigation.map((item) => (
                <NavLink to={item.to} className="font-medium" key={item.name}>
                  <Disclosure.Button
                    as="a"
                    className={({ isActive }) => {
                      return (
                        "block rounded-md px-3 py-2 text-base" +
                        (isActive
                          ? " font-bold underline underline-offset-2"
                          : "")
                      );
                    }}
                  >
                    {item.name}
                  </Disclosure.Button>
                </NavLink>
              ))}
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
}
