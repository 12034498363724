import React from "react";

const Loading = ({ height = 700 }) => {
  return (
    <div
      className={`h-[${height}px] max-w-sm rounded-md overflow-hidden shadow-xl animate-pulse`}
    >
      <div className="w-full h-80 bg-gray-300"></div>
      <div className="px-6 py-4">
        <div className="w-3/4 h-6 bg-gray-300 mb-2"></div>
        <div className="w-full h-4 bg-gray-300"></div>
      </div>
      <div className="px-6 pt-4 pb-2">
        <div className="w-1/4 h-4 bg-gray-300 mb-2"></div>
        <div className="w-1/2 h-4 bg-gray-300 mb-2"></div>
        <div className="w-1/3 h-4 bg-gray-300 mb-2"></div>
        <div className="w-2/3 h-4 bg-gray-300 mb-2"></div>
        <div className="w-1/4 h-4 bg-gray-300 mb-2"></div>
        <div className="w-1/2 h-4 bg-gray-300 mb-2"></div>
        <div className="w-2/3 h-4 bg-gray-300 mb-2"></div>
      </div>
    </div>
  );
};

export default Loading;
