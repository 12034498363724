import React from "react";

const Input = ({ label, name, type, value, onChange, error }) => (
  <div className="mb-4">
    <label className="block text-sm font-medium text-gray-700">{label}</label>
    <input
      name={name}
      type={type}
      value={value}
      onChange={onChange}
      className={`mt-1 block w-full px-4 py-3 bg-gray-50 border ${
        error ? "border-red-500" : "border-gray-300"
      } rounded-md shadow-sm focus:outline-none focus:border-brand-orange focus:ring focus:ring-brand-orange focus:ring-opacity-50`}
    />
    {error && <p className="text-red-500 text-xs mt-1">{error}</p>}
  </div>
);

export default Input;
