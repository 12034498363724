import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  PhoneIcon,
  EnvelopeIcon,
  MapPinIcon,
  CalendarIcon,
  XMarkIcon,
} from "@heroicons/react/24/solid";
import defPhoto from "../assets/default.jpg";
import logoAnimation from "../assets/S.mp4";
import Modal from "react-modal";
import { useText } from "../utils/LanguageContext";
import { useQuery, useMutation } from "@tanstack/react-query";
import request from "../utils/request";
import { displayError, displaySuccess } from "../common/Dropdown";
import { useAuth } from "../utils/AuthContext";

const Photographer = () => {
  const [selectedImage, setSelectedImage] = useState(null);
  const [isBookingModalOpen, setIsBookingModalOpen] = useState(false);
  const { authToken } = useAuth();
  const [bookingData, setBookingData] = useState({
    eventDate: "",
    eventType: "",
    eventLocation: "",
    description: "",
  });
  let location = useLocation();
  const navigate = useNavigate();

  const id =
    location.state?.photographer?.id || location.state?.photographer?._id;
  const photographerTexts = useText("photographer");

  // Fetch photographer data using React Query
  const {
    data: photographer,
    isLoading,
    error,
  } = useQuery({
    queryKey: ["photographer", id],
    queryFn: async () => {
      const response = await request(`users/${id}`, "GET");
      return response.data;
    },
    enabled: !!id, // only run if id is available
  });

  // Mutation to submit booking
  const bookingMutation = useMutation({
    mutationFn: async (bookingPayload) => {
      return await request("booking/", "POST", bookingPayload);
    },
    onSuccess: (response) => {
      if (response.status === "success") {
        displaySuccess(photographerTexts.success);
        setIsBookingModalOpen(false);
      }
    },
    onError: () => {
      displayError(photographerTexts.fail);
    },
  });

  const handleBooking = () => {
    if (authToken) {
      setIsBookingModalOpen(true);
    } else {
      navigate("/auth");
    }
  };

  const closeBookingModal = () => {
    setIsBookingModalOpen(false);
  };

  const handleBookingChange = (e) => {
    const { name, value } = e.target;
    setBookingData({ ...bookingData, [name]: value });
  };

  const submitBooking = () => {
    const userId = photographer._id;
    const bookingPayload = {
      userId: userId,
      photographerId: id,
      eventDate: bookingData.eventDate,
      eventLocation: bookingData.eventLocation,
      eventType: bookingData.eventType,
      description: bookingData.description,
      status: "pending",
    };
    bookingMutation.mutate(bookingPayload);
  };

  const openImageModal = (image) => {
    setSelectedImage(image);
  };

  const closeImageModal = () => {
    setSelectedImage(null);
  };

  if (isLoading) {
    return (
      <div className="flex items-center justify-center min-h-screen bg-white">
        <video className="w-32 h-32" src={logoAnimation} autoPlay loop muted />
      </div>
    );
  }

  if (error || !photographer) {
    return (
      <div className="flex items-center justify-center min-h-screen bg-white">
        <div className="text-center">
          <img
            src={defPhoto}
            alt="Default"
            className="w-40 h-40 object-cover rounded-full border-4 border-yellow-500"
          />
          <h1 className="text-4xl font-bold text-center text-yellow-500 mt-4">
            {photographerTexts.error}
          </h1>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-50 flex flex-col items-center py-12 font-sans">
      <div className="w-full max-w-7xl p-8 bg-white rounded-lg shadow-lg">
        <div className="flex flex-col md:flex-row items-center md:space-x-10 mb-12">
          <div className="w-5/6 flex flex-col items-center space-y-4 mb-8 md:mb-0 md:w-1/3">
            <div className="w-56 h-56 rounded-full overflow-hidden border-4 border-yellow-500 shadow-lg">
              <img
                src={photographer?.photo || defPhoto}
                alt="Photographer"
                className="w-full h-full object-cover"
              />
            </div>
            <h1 className="text-4xl font-bold text-yellow-500 text-center">
              {photographer.fullName}
            </h1>
            <div className="flex items-center space-x-2 text-gray-600">
              <MapPinIcon className="h-6 w-6 text-yellow-500" />
              <span>{`${photographer.location.city}, ${photographer.location.state}, ${photographer.location.country}`}</span>
            </div>
            <button
              onClick={handleBooking}
              className="mt-4 px-6 py-2 bg-yellow-500 text-white font-bold rounded-lg hover:bg-yellow-600"
            >
              Book Now
            </button>
          </div>
          <div className="flex-grow space-y-8 w-full md:w-2/3">
            <div>
              <h2 className="text-3xl font-bold text-yellow-500 mb-4">About</h2>
              <p className="text-lg text-gray-700">{photographer.about}</p>
            </div>
            <div>
              <h2 className="text-3xl font-bold text-yellow-500 mb-4">
                {photographerTexts.contact}
              </h2>
              <div className="flex items-center space-x-2 text-gray-700">
                <PhoneIcon className="h-6 w-6 text-yellow-500" />
                <span>{photographer.mobileNumber}</span>
              </div>
              <div className="flex items-center space-x-2 text-gray-700 mt-2">
                <EnvelopeIcon className="h-6 w-6 text-yellow-500" />
                <span>{photographer.email}</span>
              </div>
            </div>
            <div>
              <h2 className="text-3xl font-bold text-yellow-500 mb-4">
                Price Range
              </h2>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4 text-lg text-gray-700">
                {Object.entries(photographer.priceRange).map(
                  ([eventType, price]) => (
                    <div
                      key={eventType}
                      className="flex items-center justify-between bg-gray-100 p-4 rounded-lg shadow-sm"
                    >
                      <span className="font-semibold capitalize">
                        {eventType}:
                      </span>
                      <span className="text-yellow-500">${price}</span>
                    </div>
                  )
                )}
              </div>
            </div>
            <div>
              <h2 className="text-3xl font-bold text-yellow-500 mb-4">
                Booking Count
              </h2>
              <div className="flex items-center space-x-2 text-gray-700">
                <CalendarIcon className="h-6 w-6 text-yellow-500" />
                <span>{photographer.bookingCount}</span>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-12 w-full">
          <h2 className="text-3xl font-bold text-yellow-500 mb-6">Portfolio</h2>
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
            {photographer.portfolio?.map((item, index) => (
              <div key={index} className="relative group">
                <img
                  src={item || defPhoto}
                  alt={`Portfolio ${index}`}
                  className="w-full h-full object-cover rounded-lg transition-transform transform group-hover:scale-105 cursor-pointer"
                  onClick={() => openImageModal(item)}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
      {selectedImage && (
        <Modal
          isOpen={true}
          onRequestClose={closeImageModal}
          className="flex items-center justify-center"
          overlayClassName="fixed inset-0 bg-black bg-opacity-50"
        >
          <div className="bg-white p-4 rounded-lg shadow-lg max-w-3xl">
            <img
              src={selectedImage}
              alt="Selected"
              className="w-full h-auto rounded-lg"
            />
            <button
              onClick={closeImageModal}
              className="mt-4 px-6 py-2 bg-yellow-500 text-white font-bold rounded-lg hover:bg-yellow-600"
            >
              Close
            </button>
          </div>
        </Modal>
      )}
      {isBookingModalOpen && (
        <Modal
          isOpen={true}
          onRequestClose={closeBookingModal}
          className="flex items-center justify-center"
          overlayClassName="fixed inset-0 bg-black bg-opacity-50"
        >
          <div className="relative bg-white p-8 rounded-lg shadow-lg max-w-lg w-full">
            {/* Close Button */}
            <button
              onClick={closeBookingModal}
              className="absolute top-4 right-4 text-gray-500 hover:text-gray-700 focus:outline-none"
            >
              <XMarkIcon className="h-6 w-6" />
            </button>

            <h2 className="text-3xl font-bold text-yellow-500 mb-6">
              Book Photographer
            </h2>
            <div className="space-y-4">
              <div>
                <label className="block text-gray-700 mb-2">Event Date</label>
                <input
                  type="datetime-local"
                  name="eventDate"
                  value={bookingData.eventDate}
                  onChange={handleBookingChange}
                  className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-yellow-500"
                />
              </div>
              <div>
                <label className="block text-gray-700 mb-2">Event Type</label>
                <select
                  name="eventType"
                  value={bookingData.eventType}
                  onChange={handleBookingChange}
                  className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-yellow-500"
                >
                  <option value="" disabled>
                    Select an event type
                  </option>
                  {Object.keys(photographer.priceRange).map((eventType) => (
                    <option key={eventType} value={eventType}>
                      {eventType.charAt(0).toUpperCase() + eventType.slice(1)}
                    </option>
                  ))}
                </select>
              </div>
              <div>
                <label className="block text-gray-700 mb-2">
                  Event Location
                </label>
                <input
                  type="text"
                  name="eventLocation"
                  value={bookingData.eventLocation}
                  onChange={handleBookingChange}
                  className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-yellow-500"
                />
              </div>
              <div>
                <label className="block text-gray-700 mb-2">Description</label>
                <textarea
                  name="description"
                  value={bookingData.description}
                  onChange={handleBookingChange}
                  className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-yellow-500"
                  rows="4"
                  placeholder="Describe your event"
                ></textarea>
              </div>
              <button
                onClick={submitBooking}
                className="mt-4 w-full px-6 py-2 bg-yellow-500 text-white font-bold rounded-lg hover:bg-yellow-600"
              >
                Submit
              </button>
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
};

export default Photographer;
