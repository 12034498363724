import React from "react";
import Slider from "react-slick";
import banner1 from "../assets/banner.webp";
import banner2 from "../assets/banner.webp";
import banner3 from "../assets/banner.webp";
import Random1 from "../assets/top1.jpeg";
import Random2 from "../assets/top2.jpeg";
import Random3 from "../assets/top3.jpeg";
import { NavLink } from "react-router-dom";
import { useText } from "../utils/LanguageContext";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useQuery } from "@tanstack/react-query";
import request from "../utils/request";
import Loading from "../components/Loading";

const banners = [banner1, banner2, banner3];

const dishes = [
  {
    id: 1,
    src: Random1,
    title: "Giorgi Sajaia",
    description:
      "As a photographer specializing in nature and landscape imagery, I strive to capture the ephemeral beauty of the natural world. My hourly rate is $75, and I'm available for bookings nationwide. Contact me at (555) 672-9384 to discuss your photography needs.",
    price: "$75/hour",
    phone: "555-555-555",
  },
  {
    id: 2,
    src: Random2,
    title: "Avto shervashidze",
    description:
      "I'm a passionate portrait photographer who loves capturing the unique stories of people through my lens. My hourly rate is $100, and I aim to provide a personalized experience. You can reach me at (555) 314-1592 to book a session.",
    price: "$100/hour",
    phone: "555-555-555",
  },
  {
    id: 3,
    src: Random3,
    title: "Tako kilasonia",
    description:
      "Capturing moments from weddings to corporate events, I bring a creative eye to all my projects. My hourly rate is $85. Call me at (555) 491-0023 for availability.",
    price: "$85/hour",
    phone: "555-555-555",
  },
];

function Home() {
  const homeTexts = useText("home");

  const { data, isLoading } = useQuery({
    queryKey: "dishes",
    queryFn: () => request("users/photographers/top"),
  });
  console.log(data);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    swipe: true,
    touchThreshold: 100,
  };

  return (
    <>
      {/* Section 1 */}
      <div className="relative isolate group">
        <Slider {...settings}>
          {banners.map((banner, index) => (
            <div key={index} className="h-[25rem] sm:h-[35rem] w-full relative">
              <img
                src={banner}
                alt=""
                className="h-full w-full object-cover object-center transition duration-500 ease-in-out transform scale-100 group-hover:scale-110"
              />
            </div>
          ))}
        </Slider>
        <div className="absolute inset-0 bg-black bg-opacity-50 flex flex-col justify-center px-6 sm:px-64">
          <div className="max-w-7xl">
            <div className="max-w-xl">
              <h2 className="text-3xl sm:text-4xl font-bold tracking-tight text-[#FAC463] drop-shadow-lg font-[Poppins]">
                {homeTexts.name}
              </h2>
              <p className="text-white mt-4 text-base sm:text-lg leading-6 drop-shadow-lg font-[Poppins]">
                {homeTexts.subtitle}
              </p>
              <p className="text-white mt-4 text-base sm:text-lg leading-6 drop-shadow-lg font-[Poppins]">
                {homeTexts.txt}
              </p>
            </div>
            <div className="mt-6 max-w-xl">
              <NavLink
                to="/menu"
                className="rounded-md duration-200 delay-100 bg-[#FAC463] text-[#000] px-4 py-2 text-sm sm:text-base font-semibold shadow-sm hover:bg-black hover:text-white font-[Poppins]"
              >
                {homeTexts.book}
              </NavLink>
            </div>
          </div>
        </div>
      </div>

      {/* Section 2 */}
      <div className="py-12 sm:py-16">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="flex justify-between items-center mb-8">
            <h2 className="text-2xl sm:text-3xl font-bold tracking-tight">
              {homeTexts.top}
            </h2>
            <NavLink
              to="/menu"
              className="hidden md:inline-block rounded-md duration-200 delay-100 bg-[#FAC463] text-[#000] px-4 py-2 text-sm sm:text-base font-semibold shadow-md hover:bg-black hover:text-white"
            >
              {homeTexts.all}
            </NavLink>
          </div>
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
            {isLoading ? (
              <>
                <Loading height={700} />
                <Loading height={700} />
                <Loading height={700} />
              </>
            ) : (
              (data?.data || []).map((item) => (
                <NavLink
                  to={{
                    pathname: "/photographer",
                  }}
                  state={{ photographer: item }}
                  key={item.id}
                >
                  <div className="max-w-sm rounded-md overflow-hidden shadow-lg group h-[450px]">
                    <div className="relative">
                      <img
                        className="w-full h-48 sm:h-64 object-cover transform transition duration-500 ease-in-out group-hover:scale-110"
                        src={item.photo}
                        alt="Dish"
                      />
                      <button className="absolute inset-0 flex items-center justify-center opacity-0 transition-opacity duration-500 group-hover:opacity-100">
                        <div className="bg-[#FAC463] text-black py-2 px-4 rounded-md">
                          Book Now
                        </div>
                      </button>
                    </div>
                    <div className="px-4 py-4 overflow-scroll-y">
                      <div className="font-bold text-base sm:text-lg mb-2">
                        {item.fullName}
                      </div>
                      <p className="text-sm sm:text-base">{item.about}</p>
                    </div>
                    {/* <div className="px-4 pb-4">
                    <p className="text-sm sm:text-base py-1">{item.price}</p>
                  </div> */}
                  </div>
                </NavLink>
              ))
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default Home;
