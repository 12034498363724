import React, { forwardRef, useImperativeHandle, useState } from "react";
import { Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";

const DropdownAlert = forwardRef(({ duration = 5000 }, ref) => {
  const [isVisible, setIsVisible] = useState(false);
  const [alertType, setAlertType] = useState(null);
  const [message, setMessage] = useState("");

  useImperativeHandle(ref, () => ({
    show({ message, type }) {
      setAlertType(type);
      const defaultMsg = type === "success" ? "Success" : "Error";
      const _msg = message || defaultMsg;

      setMessage(_msg);

      setIsVisible(true);

      setTimeout(() => {
        setIsVisible(false);
      }, duration);
    },
  }));

  return (
    <Transition
      show={isVisible}
      enter="transform transition ease-in-out duration-500"
      enterFrom="-translate-y-full"
      enterTo="translate-y-0"
      leave="transform transition ease-in-out duration-500"
      leaveFrom="translate-y-0"
      leaveTo="-translate-y-full"
      className={`fixed top-4 left-1/2 transform -translate-x-1/2 w-80 p-4 z-50 flex justify-between items-center space-x-3 rounded-lg shadow-lg ${
        alertType === "success"
          ? "bg-green-500"
          : alertType === "error"
          ? "bg-red-500"
          : ""
      } text-white`}
    >
      <h3 className="font-medium">{message}</h3>
      <button onClick={() => setIsVisible(false)}>
        <XMarkIcon className="w-6 h-6" />
      </button>
    </Transition>
  );
});

export default DropdownAlert;
