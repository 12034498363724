let DropdownAlert = null;

const setDropDown = (ref) => {
  DropdownAlert = ref;
};

const displayError = (message) => {
  DropdownAlert.show({ message, type: "error" });
};

const displaySuccess = (message) => {
  DropdownAlert.show({ message, type: "success" });
};

export { setDropDown, DropdownAlert, displayError, displaySuccess };
